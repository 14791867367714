import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["href"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_TravelDetails = _resolveComponent("TravelDetails")

  return (_openBlock(), _createBlock(_component_TravelDetails, {
    "data-test": "travel-subpage-completed",
    itineraries: _ctx.completedItineraries,
    "is-past-travel": ""
  }, {
    footer: _withCtx(() => [
      _cache[0] || (_cache[0] = _createTextVNode(" Completed itineraries are limited to the past 12 months; for inquiries about older itineraries, contact your travel team at ")),
      _createElementVNode("a", {
        href: $options.travelPhone,
        target: "_blank",
        class: "text-highlight-800 font-500 hover:text-highlight-600 no-underline"
      }, _toDisplayString($options.travelPhoneText), 9, _hoisted_1)
    ]),
    _: 1
  }, 8, ["itineraries"]))
}